@import "../../styles/_colors.scss";

.standard-rating-compliance-progress-bar {
    // Handle different bar sizes.
    &.standard-rating-compliance-progress-bar-size-md {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }
    &.standard-rating-compliance-progress-bar-size-lg {
        margin-top: 5px !important;
        height: 30px !important;
        margin-bottom: 5px !important;
    }
    // We use a transparent bar on the progress bar to label the unanswered questions.
    .standard-rating-compliance-progress-bar-transparent {
        background-color: transparent !important;
        color: $main-color !important;
    }
}
