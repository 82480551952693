@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";
@import "../../styles/_variables.scss";

$app-header-height: $navbar-height;
$app-body-margin-top: $app-header-height;

$app-header-no-categories-height: $navbar-categories-collapsed-height;
$app-body-no-caegory-margin-top: $app-header-no-categories-height;


// Adjust the body margin for the extra large navbar.
.header-fixed {
    .app-header {
        height: $app-header-height;
    }

    .app-body {
        margin-top: $app-body-margin-top;
    }
}

.app-header {
    background-color: $white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .header-top-section {
        display: flex;
        width: 100%;
        //border-bottom: solid 1px #ECECEC;
        padding-left: 1%;
        padding-right: 1%;

        .header-mobile-search-button {
            display: none;
            margin-top: 16px;
            cursor: pointer;
            height: 26px;
            width: 26px;
            background-image: url(../../styles/images/header/searchlarge.png);
            background-repeat: no-repeat;
            background-size: contain;

            @include media-breakpoint-down(sm) {
                display: flex;
            }
        }

        .header-logo-wrapper {
            padding-top: 12px;
            padding-left: 28px;
            display: flex;

            @include media-breakpoint-down(md) {
                display: none !important;
            }

            .header-tsb-logo {
                width: 160px;
                height: 100%;
                margin-right: 15px;
                background-image: url(../../styles/images/header/tsb-logo.png);
                background-size: contain;
                background-position-x: center;
                background-position-y: 16px;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            .header-tnc-logo {
                width: 81px;
                max-width: 81px;
                height: 100%;
                margin-left: 2px;
                background-image: url(../../styles/images/header/Discover_NEG.png);
                background-size: contain;
                background-position-x: center;
                background-position-y: 8px;
                background-repeat: no-repeat;
                cursor: pointer;
            }
        }

        .header-top-section-spacing {
            flex-grow: 2;


            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }

        .header-top-right {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-top: 8px;
            margin-right: 36px;

            @include media-breakpoint-down(sm) {
                margin-right: 0px;
            }

            .header-search-account {
                display: flex;
                flex-direction: row;
                margin-left: auto;
                margin-right: 0px;
                padding-bottom: 10px;
                /*padding-top: 10px;*/

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }
                /* Logo that shows on medium sized screens. */
                .header-search-account-logo-wrapper {
                    padding-top: 8px;
                    padding-left: 0px;
                    display: none;
                    max-height: 42px; /* 12 padding plus 30 desired height */

                    @include media-breakpoint-down(md) {
                        display: flex;
                    }

                    .header-tsb-logo {
                        width: 120px;
                        height: 100%;
                        margin-right: 5px;
                        background-image: url(../../styles/images/header/tsb-logo.png);
                        background-size: contain;
                        background-position-x: center;
                        background-repeat: no-repeat;
                        margin-top: 8px;
                        cursor: pointer;
                    }
                    .header-tnc-logo {
                        width: 64px;
                        max-width: 64px;
                        min-height: 30px;
                        height: 100%;
                        margin-left: 2px;
                        background-image: url(../../styles/images/header/Discover_NEG.png);
                        background-size: contain;
                        background-position-x: center;
                        background-position-y: 2px;
                        background-repeat: no-repeat;
                        cursor: pointer;
                    }
                }

                .header-search-account-spacing-before {
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        flex-grow: 4;
                    }
                }

                .header-search-account-spacing-after {
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        flex-grow: 4;
                    }
                }

                .header-search-bar-container {
                    max-width: 800px;
                    margin-right: 5px;
                    border: 1px solid $theschoolbus-blue;
                    border-radius: 3px;
                    background-color: $theschoolbus-lightgray;
                    color: $theschoolbus-blue;
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: flex;
                        width: 20vw;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 33vw;
                    }

                    @include media-breakpoint-up(xl) {
                        width: 50vw;
                    }


                    .header-search-bar-icon {
                        font-size: 16px;
                        padding-left: 10px;
                        padding-top: 4px;
                        padding-right: 8px;
                        height: 22px;
                        //padding-bottom: 5px;
                    }

                    .header-search-bar-input {
                        border-style: none;
                        background-color: $theschoolbus-lightgray;
                        color: $theschoolbus-blue;
                        height: 100%;
                        font-size: 14px;
                        font-weight: 600;

                        &:focus {
                            box-shadow: none;
                        }

                        &::placeholder {
                            color: $theschoolbus-blue;
                            font-weight: 600;
                        }
                    }
                }

                .header-my-account-wrapper {
                    color: $theschoolbus-blue;
                    padding-top: 6px;
                    padding-left: 8px;
                    padding-right: 8px;
                    font-size: 24px;

                    @include media-breakpoint-up(md) {
                        border-left: 1px solid $theschoolbus-lightgray;
                        border-right: 1px solid $theschoolbus-lightgray;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-right: 0px;
                    }
                }
            }

            .header-toolbar-container {
                display: flex;
                flex-direction: row;
                margin-left: auto;
                margin-right: 0px;
                // Adjust on md size to not go over the bottom of the navbar.
                @include media-breakpoint-down(md) {
                    margin-top: -3px;
                }

                @include media-breakpoint-down(sm) {
                    display: none !important;
                }
                // Specifics that only happen to .header-toolbar under the main toolbar.
                .header-toolbar {
                    .header-toolbar-button {
                        font-size: 12px;
                    }

                    &.header-toolbar-active, &:hover, &:focus, &:active {
                        border-bottom: 2px solid $theschoolbus-rust;

                        .header-toolbar-button {
                            color: $theschoolbus-rust;
                        }

                        .header-toolbar-icon {
                            &.header-toolbar-icon-construction {
                                background-image: url(../../styles/images/header/construction_hover.png);
                            }

                            &.header-toolbar-icon-download {
                                background-image: url(../../styles/images/header/download_hover.png);
                            }

                            &.header-toolbar-icon-list {
                                background-image: url(../../styles/images/header/list_hover.png);
                            }

                            &.header-toolbar-icon-update {
                                background-image: url(../../styles/images/header/update_hover.png);
                            }

                            &.header-toolbar-icon-verified {
                                background-image: url(../../styles/images/header/verified_hover.png);
                            }

                            &.header-toolbar-icon-view_headline {
                                background-image: url(../../styles/images/header/view_headline_hover.png);
                            }
                        }
                    }
                }
            }
            // NOTE this isn't under .header-toolbar-container as it also applies to the Account navigation.
            .header-toolbar {
                padding-left: 12px;
                padding-right: 12px;
                display: flex;
                justify-content: space-evenly;
                border-right: 1px solid #b5b5b5;
                border-bottom: 2px solid transparent;
                // Last button has no border on the right.
                &:last-child {
                    border-right-style: none;
                }
                // On desktop screens we want to hide some buttons.
                @media (min-width: $collapse-categories-max-width) {
                    &.header-toolbar-hidden-fullwidth {
                        display: none !important;
                    }
                }


                .header-toolbar-button {
                    // Undo everything we inherit from btn.
                    border-style: none !important;
                    border-radius: 0px;
                    text-transform: uppercase;

                    &:hover, &:focus, &:active {
                        border-style: none !important;
                        background-color: $white !important;
                        box-shadow: none !important;
                    }
                    // Now introduce our own styles.
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    color: $theschoolbus-blue;
                    width: 100%;
                    height: 100%;
                    font-weight: 600;

                    .header-toolbar-icon {
                        height: 22px;
                        width: 22px;
                        background-size: 22px 22px;
                        padding-right: 5px;
                        background-repeat: no-repeat;
                    }
                }


                .header-toolbar-menu {
                    width: 340px;
                    border-style: none;
                    border-radius: 0px;
                    // Adjust so we align with the whole toolbar block, not just the button.
                    &.dropdown-menu-right {
                        margin-right: -14px;
                    }

                    &:not(.dropdown-menu-right) {
                        margin-left: -14px;
                    }

                    .header-toolbar-item {
                        padding: 19px;
                        width: 100%;
                        text-wrap: wrap;

                        .header-toolbar-item-title {
                            color: $theschoolbus-blue;
                            font-size: 0.875rem;
                            font-weight: 600;
                            line-height: 1.25rem;
                        }

                        &:hover, &:focus, &:active {
                            outline: none !important;
                            background-color: $theschoolbus-lightgray !important;

                            .header-toolbar-item-title {
                                color: $theschoolbus-rust;
                            }
                        }

                        .header-toolbar-item-description {
                            color: $theschoolbus-darkgray;
                            width: 100%;
                            font-size: 0.75rem;
                            font-weight: 500;
                            line-height: 1rem;
                        }
                    }
                }
            }
        }
    }

    .header-category-links {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        border-top: solid 1px #ECECEC;

        .header-category-link {
            display: flex;
            height: 58px;
            font-size: 12px;
            color: $theschoolbus-blue;
            line-height: 16px;
            border-bottom: solid 2px $white;
            align-items: center;
            cursor: pointer;
        }
    }
}

// Special handling of when the category get collapse din the navbar.
// NOTE we've hard coded the max-width to match
@media (max-width: $collapse-categories-max-width) {
    .app-header {
        .header-category-links {
            display: none !important;
        }
    }
    // Adjust the body margin for the extra large navbar.
    .header-fixed {
        .app-header {
            height: $app-header-no-categories-height;
        }

        .app-body {
            margin-top: $app-body-no-caegory-margin-top;
        }
    }
}

// This is kept outside of .app-header because its also used on the sidebar, and we want them to be consistant and maintained in just one place.
// Set the various icons availble which we control by classes.
// We have both normal and hover versions of this.
.header-toolbar-icon {
    &.header-toolbar-icon-construction {
        background-image: url(../../styles/images/header/construction.png);
    }

    &.header-toolbar-icon-download {
        background-image: url(../../styles/images/header/download.png);
    }

    &.header-toolbar-icon-list {
        background-image: url(../../styles/images/header/list.png);
    }

    &.header-toolbar-icon-update {
        background-image: url(../../styles/images/header/update.png);
    }

    &.header-toolbar-icon-verified {
        background-image: url(../../styles/images/header/verified.png);
    }

    &.header-toolbar-icon-view_headline {
        background-image: url(../../styles/images/header/view_headline.png);
    }
}

// Sidebar toggle needs its own special design.
.app-header {
    .navbar-toggler {
        .navbar-toggler-icon {
            background-image: url(../../styles/images/header/menu.png);
        }

        &:hover, &:active, &:focus {
            border-style: none !important;
            outline: none !important;

            .navbar-toggler-icon {
                background-image: url(../../styles/images/header/menu.png);
            }
        }
    }
}

.sidebar-show {
    .app-header {
        .navbar-toggler {
            .navbar-toggler-icon {
                background-image: url(../../styles/images/header/close.png);
            }

            &:hover, &:active, &:focus {
                border-style: none !important;
                outline: none !important;

                .navbar-toggler-icon {
                    background-image: url(../../styles/images/header/close.png);
                }
            }
        }
    }
}
