.html-display {
    // Remove the horizontal padding added by the ql-editor class
    .ql-editor {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    p {
        margin-bottom: 2px !important;
    }
}
