@import "../../styles/_colors.scss";
@import "../../styles/_variables.scss";

.sticky-toolbar {
    padding-bottom: 4px;

    &.sticky-toolbar-unstuck {
    }

    &.sticky-toolbar-stuck {
        position: fixed;
        top: $navbar-height;
        left: 0px;
        right: 0px;
        z-index: 1017;
        padding-top: 4px;

        @media (max-width: $collapse-categories-max-width) {
            top: $navbar-categories-collapsed-height;
        }
    }
    // Give some space between buttons that are not in button groups as this is a very common use case for the toolbar.
    .btn {
        margin-right: 2px !important;
    }

    .btn-group {
        margin-right: 2px !important;

        .btn {
            margin-right: 0px !important;
        }
    }
}
