@import "../../styles/_colors.scss";

.awards {
    .awards-code-block {
        display: block;
        background-color: $gray-200;

        pre {
            color: darkred;
        }

        margin-top: 0.3rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 0.3rem;
    }
}
