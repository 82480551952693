@import "../../styles/_breakpoints.scss";

.school-overview {
    .school-overview-h2 {
        display: inline;
    }

    .school-overview-logo {
        display: inline-block;
        height: 100%;
        width: 150px;
        background-image: url(../../styles/images/tcs-logo/tcs.png);
        background-size: contain;
        background-position-x: left;
        background-position-y: center;
        background-repeat: no-repeat;

        @include media-breakpoint-down(md) {
            width: 100%;
            background-position-x: center;
        }
    }

    .school-overview-school-name {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
