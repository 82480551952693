@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

//$background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)); //, url(/img/background.jpg);

.banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    //background-image: $background-image;
    .sticky-toolbar.sticky-toolbar-stuck {
        background-color: $light-bg;
        color: $light-color;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
  //      background-image: $background-image;
        border-bottom-color: $secondary;
        border-bottom-style: solid;
    }

    color: $light-color;

    &.banner-fluid {
        background-color: $light-bg;
        color: $light-color;
    }
}

// Padding and sizes on different screen sizes.
.banner {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 15px;
}

@include media-breakpoint-up(sm) {
    .banner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@include media-breakpoint-up(md) {
    .banner {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
