@import "../../styles/_colors.scss";

.report-history-card {
    // Each report needs to show as a seperate item.
    .report-history-card-report {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $light-gray;
        margin-bottom: 0.4rem;
    }
}
