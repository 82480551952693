@import "../../styles/_colors.scss";

.edit-action-modal {
    .modal-header {
        .close {
            color: $white;
            opacity: 0.9;
        }
    }
}
