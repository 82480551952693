.area-certificate-thumbnail {
    min-width: 90px;
    min-height: 42px;
    background-repeat: no-repeat;
    background-size: contain;

    &.area-certificate-thumbnail-bronze {
        background-image: url("../schoolCompliance/awardThumbnails/Award_Bronze.png");
    }

    &.area-certificate-thumbnail-silver {
        background-image: url("../schoolCompliance/awardThumbnails/Award_Silver.png");
    }

    &.area-certificate-thumbnail-gold {
        background-image: url("../schoolCompliance/awardThumbnails/Award_Gold.png");
    }
}
