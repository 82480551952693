@import "../../styles/_colors.scss";

.mat-overview {
    // Get some space around the subheadings and make it look clickable.
    .mat-overview-schools-subheading {
        margin-top: 1rem;
        margin-bottom: 0.8rem;
        cursor: pointer;
    }
}
