.video-tile {
    //border: 1px solid lightgray;
    //border-radius: 2px 2px;
    //background-color: darkgray;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    text-align: center;
    cursor: pointer;

    &.video-tile-size-xs {
        max-height: 60px;
        max-width: 107px;

        .video-tile-thumbnail-overlay {
            font-size: 0.6rem;
        }
    }

    &.video-tile-size-sm {
        max-height: 180px;
        max-width: 214px;

        .video-tile-thumbnail-overlay {
            font-size: 3rem;
        }
    }
}
