@import "_colors.scss";
@import "_fonts.scss";

// Application specific variables to control bootstrap.
//

// Application specific variables to control coreui.
//

// Navbar
$navbar-height: 149px;
$navbar-brand-width:        300px;
$navbar-border: ( bottom: ( style: none ) ) !default;

// Custom navbar handling for the categories section that only shows on desktop.
$navbar-categories-collapsed-height: 89px;
$collapse-categories-max-width: 1179px;

// Sidebar
$sidebar-bg: $theschoolbus-blue;

