@import "../../styles/_colors.scss";
@import "../../configure/integrationConfig.scss";

.mat-summary-card {
    .mat-summary-card-progress-container {
        /*        // Want a border line at the bottom as a seperator.
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $light-gray;
        margin-bottom: 0.4rem;*/
        // Formatting of the label within the custom progress bar
        .mat-summary-card-progress-label {
            display: flex;
            justify-content: center;
            text-align: center;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            user-select: none;

            @if $isIntegratedInSchoolBus {
                font-size: 66px;
                padding-top: 68px;
            }
            @else {
                font-size: 72px;
                padding-top: 50px;
            }
        }
    }
    // Thumbnail icons for each award level.
    .mat-summary-card-award-thumbnail {
        min-width: 90px;
        min-height: 42px;
        background-repeat: no-repeat;
        background-size: contain;

        &.mat-summary-card-award-thumbnail-bronze {
            background-image: url("../schoolCompliance/awardThumbnails/Award_Bronze.png");
        }

        &.mat-summary-card-award-thumbnail-silver {
            background-image: url("../schoolCompliance/awardThumbnails/Award_Silver.png");
        }

        &.mat-summary-card-award-thumbnail-gold {
            background-image: url("../schoolCompliance/awardThumbnails/Award_Gold.png");
        }
    }
}
