@import "./integrationConfig.scss";

// Pull in our variables for coreui/bootstrap.
@import "../components/layout/styles/_variables.scss";

// Start with the coreui theme (it will pull in bootstrap for us).
@import "../../node_modules/@coreui/coreui/scss/coreui.scss";
// Temp fix for reactstrap's menu-right.
@import '../../node_modules/@coreui/coreui/scss/_dropdown-menu-right.scss';
// ie fixes
@import "../components/layout/styles/_ie-fix.scss";

@if not($isIntegratedInSchoolBus) {
    // Use the body colours.
    body {
        background-color: $body-bg;
        color: $body-color;
        //background-image: url(/img/background.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
}