@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

//$background-image: linear-gradient(rgba(186, 186, 188, 0.3), rgba(186, 186, 188, 0.5)), url(/img/background/students-377789.jpg);

.background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    background-color: $body-bg;
    //color: $light-bg;
    //background-image: $background-image;
    height: auto;
    min-height: 100%;
    // If we are showing a Banner within a background, turn off the banner's background image.
    // If we want the banner background image to be different to the Background image we may want to turn this off.
    .banner {
/*        background-image: none;
        background-color: transparent;*/
        // We also want to get rid of excess height and padding at the bottom.
        height: auto;
        padding-bottom: 6px;
    }
}