.loading-and-calculating-placeholder {
    .loading-and-calculating-placeholder-image {
        min-height: 80px;
        width: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;
        margin-left: 6px;
        animation: loadingAndCalculatingPlaceholderImageChangeBg 2.5s infinite;
        text-align: center;
        padding-top: 20px;
    }

    .loading-and-calcualting-placeholder-text {
        display: flex;
        align-items: center;
    }
}

@keyframes loadingAndCalculatingPlaceholderImageChangeBg {
    0%,100% {
        background-image: url("../schoolCompliance/awardThumbnails/Award_Bronze.png");
    }

    33% {
        background-image: url("../schoolCompliance/awardThumbnails/Award_Silver.png");
    }

    66% {
        background-image: url("../schoolCompliance/awardThumbnails/Award_Gold.png");
    }
}
