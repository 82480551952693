@import "../../styles/_colors.scss";

.compliance-progress-bar {
    // Thumbnail icons for each award level.
    .compliance-progress-bar-award-thumbnail {
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        color: $success;
        text-align: right;
        padding-right: 8px;
        padding-top: 20px;

        &.compliance-progress-bar-award-thumbnail-noaward {
            background-image: url("./awardThumbnails/Award_NoAward.png");
        }

        &.compliance-progress-bar-award-thumbnail-bronze {
            background-image: url("./awardThumbnails/Award_Bronze.png");
        }

        &.compliance-progress-bar-award-thumbnail-silver {
            background-image: url("./awardThumbnails/Award_Silver.png");
        }

        &.compliance-progress-bar-award-thumbnail-gold {
            background-image: url("./awardThumbnails/Award_Gold.png");
            margin-right: 0px; // Don't use negative margin on the last icon shown.
        }

        svg[data-icon="check-circle"] {
            background-color: $white;
            border-radius: 50%;
        }
    }

    &.compliance-progress-bar-md {
        .compliance-progress-bar-award-thumbnail {
            width: 42px;
            margin-left: -2px;
            margin-right: -2px;
            padding-right: 1px;
            padding-top: 0px;
            font-size: 1rem;
            // Special images without text for the md size of progress bar.
            // Added this at the request of Becci.
            &.compliance-progress-bar-award-thumbnail-noaward {
                margin-right: -4px;
                background-image: url("./awardThumbnails/Award_NoAward_notext.png");
            }

            &.compliance-progress-bar-award-thumbnail-bronze {
                background-image: url("./awardThumbnails/Award_Bronze_notext.png");
            }

            &.compliance-progress-bar-award-thumbnail-silver {
                background-image: url("./awardThumbnails/Award_Silver_notext.png");
            }

            &.compliance-progress-bar-award-thumbnail-gold {
                background-image: url("./awardThumbnails/Award_Gold_notext.png");
            }
        }
    }

    &.compliance-progress-bar-lg {
        .compliance-progress-bar-award-thumbnail {
            width: 85px;
            margin-left: -4px;
            margin-right: -5px;

            &.compliance-progress-bar-award-thumbnail-noaward {
                margin-right: -6px;
            }
        }
    }
    // Customise the progress bars.
    .progress-bar.bg-danger {
        background: linear-gradient($danger, $white, $danger);
    }

    .compliance-progress-bar-bar-container-bronze {
        .progress {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            background: lighten($bronze, 15%);


            .progress-bar.bg-success {
                background-color: $bronze !important;
                background: linear-gradient($bronze, $bronze-dark, $bronze);
            }
        }
    }

    .compliance-progress-bar-bar-container-silver {
        .progress {
            border-radius: 0px 0px;
            background: lighten($silver, 15%);

            .progress-bar.bg-success {
                background-color: $silver !important;
                background: linear-gradient($silver, $silver-dark, $silver);
            }
        }
    }

    .compliance-progress-bar-bar-container-gold {
        .progress {
            border-radius: 0px 0px;
            background: lighten($gold, 15%);

            .progress-bar.bg-success {
                background-color: $gold !important;
                background: linear-gradient($gold, $gold-dark, $gold);
            }
        }
    }
    // Minimized progress bars should collapse to only wide enough to  show the text numbers.
    .compliance-progress-bar-minimized {
        max-width: 40px;
        transition: max-width 2s;
    }
}
