.form-buttons {
    margin-top: 2em;
    margin-bottom: 2em;
    // Give some space between buttons.
    .btn {
        margin-right: 2px !important;
    }

    .btn-group {
        margin-right: 2px !important;

        .btn {
            margin-right: 0px !important;
        }
    }
    /*border-top: 1px solid lightgray;*/
    padding-top: 4px;
}
