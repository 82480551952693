@import "../../styles/_colors.scss";

.requirements-filter {
    // Special colouring of the gold, silver, and bronze options in the filter.
    //
    .requirements-filter-bronze {
        &.btn-secondary {
            background-color: $bronze;
            background: linear-gradient($bronze, $bronze-dark, $bronze);
        }

        &.btn-outline-secondary {
            color: $bronze-dark;
        }

        border-color: $bronze-dark;

        &:hover {
            background: $bronze-dark;
            color: $white;
        }
    }

    .requirements-filter-silver {
        &.btn-secondary {
            background-color: $silver;
            background: linear-gradient($silver, $silver-dark, $silver);
        }

        &.btn-outline-secondary {
            color: $silver-dark;
        }

        border-color: $silver-dark;

        &:hover {
            background: $silver-dark;
            color: $white;
        }
    }

    .requirements-filter-gold {
        &.btn-secondary {
            background-color: $gold;
            background: linear-gradient($gold, $gold-dark, $gold);
        }

        &.btn-outline-secondary {
            color: $gold-dark;
        }

        border-color: $gold-dark;

        &:hover {
            background: $gold-dark;
            color: $white;
        }
    }

    .requirements-filter-unanswered {
        &.btn-secondary {
            background-color: $theschoolbus-darkgray;
        }

        &.btn-outline-secondary {
            color: darken($theschoolbus-darkgray, 30%);
        }

        border-color: darken($theschoolbus-darkgray, 30%);

        &:hover {
            background: darken($theschoolbus-darkgray, 30%);
            color: $white;
        }
    }
}
